import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import Navigation from "../components/navigation"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="404: Not Found" />
      <Navigation />
      <h1>Not Found</h1>
      <p>So sad, very sad.</p>
      <Bio />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
  }
`
